<template>
  <div class="block-hover">
    <div class="position-icon pointer">
      <svg @click="$emit('openDelete')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3 6H5H21" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 11V17" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 11V17" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
      <div class="d-flex">
      <svg v-if="paramType == 'thumbnail'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M5.99316 7.99675H5.99983V8.00341H5.99316V7.99675ZM5.99316 3.33008H5.99983V3.33674H5.99316V3.33008ZM5.99316 12.6634H5.99983V12.6701H5.99316V12.6634Z" stroke="#7C848F" stroke-width="2" stroke-linejoin="round"/>
        <path d="M10 7.99675H10.0067V8.00341H10V7.99675ZM10 3.33008H10.0067V3.33674H10V3.33008ZM10 12.6634H10.0067V12.6701H10V12.6634Z" stroke="#7C848F" stroke-width="2" stroke-linejoin="round"/>
      </svg>
      <div
        v-if="(midia_block.content.length == 0 && paramType !== 'thumbnail') || (!$parent.blog.thumbnail && paramType === 'thumbnail')"
        class="drop-area p-3"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop"
        :class="{ active: isDragging }"
        @click="openFileInput"
      >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="d-block mx-auto" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6.66683 10.6667H9.3335C9.70016 10.6667 10.0002 10.3667 10.0002 10V6.66667H11.0602C11.6535 6.66667 11.9535 5.94667 11.5335 5.52667L8.4735 2.46667C8.41182 2.40487 8.33856 2.35583 8.25791 2.32238C8.17726 2.28893 8.09081 2.27171 8.0035 2.27171C7.91618 2.27171 7.82973 2.28893 7.74908 2.32238C7.66843 2.35583 7.59517 2.40487 7.5335 2.46667L4.4735 5.52667C4.0535 5.94667 4.34683 6.66667 4.94016 6.66667H6.00016V10C6.00016 10.3667 6.30016 10.6667 6.66683 10.6667ZM4.00016 12H12.0002C12.3668 12 12.6668 12.3 12.6668 12.6667C12.6668 13.0333 12.3668 13.3333 12.0002 13.3333H4.00016C3.6335 13.3333 3.3335 13.0333 3.3335 12.6667C3.3335 12.3 3.6335 12 4.00016 12Z" fill="#0062FF"/>
          </svg>
          <p class="fs-14 fw-400 mb-0 c-grey text-center">Arraste arquivos aqui para fazer Upload de arquivos de mídia</p>
          <p class="fs-10 fw-400 mb-0 c-grey text-center">Imagens e Vídeos de até 50MG</p>
          <input type="file" ref="file" id="image" class="form-control" hidden @change="uploadImage($event)" accept="image/*">
        </div>
      </div>
      <img v-if="midia_block.content.length && paramType !== 'thumbnail'" :src="midia_block.content" alt="" class="w-100 my-3">
      <img v-if="($parent.blog.thumbnail && paramType === 'thumbnail')" :src="$parent.blog.thumbnail" alt="" class="w-100 my-3">

      <div class="modal fade" :id="'addImage' + midia_block.id" tabindex="-1" role="dialog" aria-labelledby="addImageLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content bg-secondary">
            <div class="modal-body">
              <Cropper
                  :restrictions="pixelsRestriction"
                  :auto-zoom="true"
                  :src="img"
                  :stencil-props="{
                    aspectRatio: aspectRatio,
                    movable: true,
                    resizable: true,
                    width: width,
                    height: height
                  }"
                  ref="cropper"
              />

            </div>
            <div class="modal-footer bg-white">
              <label class="form-control-label p-alg"> <span class="text-danger">*</span> Tamanho Mínimo: {{ width }} x {{ height }} <span class="text-danger">*</span></label>
              <label class="form-control-label p-alg" v-if="errorImage"> <span class="text-danger">{{ errorImage }}</span></label>
              <button type="button" class="btn btn-danger" data-dismiss="modal" @click="destroyInfo()">Cancelar</button>
              <button :disabled="loading" type="button" @click="crop()" class="btn btn-success">
                {{ loading ? 'Salvando' : 'Salvar' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>
<script>

import { Cropper } from 'vue-advanced-cropper'

export default {
  props: {
    _midia_block: {
      type: Object,
      default () {
        return {}
      }
    },
    paramType: {
      type: String,
      default () {
        return 'block'
      }
    }
  },
  components: {
    Cropper
  },
  data () {
    return {
      img: null,
      isDragging: false,
      selectedFiles: [],
      midia_block: {
        content: []
      },
      loading: false,
      errorImage: null,
      aspectRatio: null,
      width: null,
      height: null
    }
  },
  methods: {
    handleDragOver (event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave () {
      this.isDragging = false
    },
    handleDrop (event) {
      event.preventDefault()
      this.isDragging = false

      const files = event.dataTransfer.files
      this.addFilesToArray(files)
    },
    handleFileInput (event) {
      const files = event.target.files
      this.addFilesToArray(files)
    },
    addFilesToArray (files) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i])
      }
      this.midia_block.content = this.selectedFiles
    },
    setMidia () {
      // this.button_block.content.push(this.button)
      this.midia_block.content = this.button
      // // this.$emit('button', this.button)
      // $('#buttonModal').modal('hide')
    },

    pixelsRestriction ({ minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight }) {
      this.errorImage = null

      if (this.paramType === 'thumbnail') {
        // se a imagem for menor que 200px
        if (imageHeight < 800 || imageWidth < 500) {
          this.errorImage = 'Essa imagem é muito pequena. Para melhor visualização do produto, opte por imagens com dimensões superiores a 600x600 px'
          return false

        // se a imagem for maior que 200px e menor que 600px
        } else if (imageHeight > 1000 || imageWidth > 700) {
          this.errorImage = 'Dica: Para melhor visualização do produto, opte por imagens com dimensões superiores a 800px x 500px'
          return {
            minWidth: minWidth,
            minHeight: minHeight,
            maxWidth: Math.min(imageWidth, maxWidth),
            maxHeight: Math.min(imageHeight, maxHeight)
          }
        }
      } else {
        // se a imagem for menor que 200px
        if (imageHeight < 200 || imageWidth < 200) {
          this.errorImage = 'Essa imagem é muito pequena. Para melhor visualização do produto, opte por imagens com dimensões superiores a 600x600 px'
          return false

        // se a imagem for maior que 200px e menor que 600px
        } else if (imageHeight < 600 || imageWidth < 600) {
          this.errorImage = 'Dica: Para melhor visualização do produto, opte por imagens com dimensões superiores a 600px x 600px'
          return {
            minWidth: minWidth,
            minHeight: minHeight,
            maxWidth: Math.min(imageWidth, maxWidth),
            maxHeight: Math.min(imageHeight, maxHeight)
          }
        }
      }
    },

    uploadImage (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.img = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
        $('#addImage' + this.midia_block.id).modal('show')
      }
    },

    crop () {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image

      if (this.paramType === 'thumbnail') {
        this.$parent.blog.thumbnail = canvas.toDataURL()
      } else {
        this.midia_block.content = canvas.toDataURL()
      }

      this.img = null
      // Evita que o clique no botão "Cortar" propague para o elemento "drop-area"
      event.stopPropagation()

      $('#addImage' + this.midia_block.id).modal('hide')

      this.destroyInfo()
    },

    openFileInput () {
      // Dispara o clique no elemento de input file
      this.$refs.file.click()
    },

    destroyInfo () {
      this.banner_url = null
      this.$parent.imageCropper = null
      this.$parent.originalImage = null
      $('#image').val(null)
    }

  },
  mounted () {
    this.midia_block = this._midia_block

    if (this.paramType === 'thumbnail') {
      this.aspectRatio = 800 / 500
      this.width = 800
      this.height = 500
    } else {
      this.aspectRatio = 400 / 250
      this.width = 400
      this.height = 250
    }
  }
}
</script>
<style scoped>
.drop-area {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100px; */
  border: 2px dashed #ccc;
  border-radius: 10px;
  font-size: 18px;
  color: #999;
}

.active {
  background-color: #f0f0f0;
}
.block-hover {
  position: relative;
}
.position-icon {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 8px;
  background: rgba(250, 250, 251, 0.75);
  padding: 8px;
  opacity: 0;
  transition: .3s;
}
.block-hover:hover .position-icon {
  opacity: 1;
  transition: .3s;
}
</style>
