<template>
  <section>
  <form @submit.prevent="save()">
    <div class="card mb-5">
      <div class="card-header bg-transparent">
        <h6 class=" mb-0">Informações principais</h6>
      </div>
      <div class="card-body pb-3">
        <div class="row">
          <div class="col-md-12 pb-3">
            <label for="title" class="form-control-label">Url Site: <span class="text-danger">*</span></label>
            <input v-model="data.url_site" class="form-control" type="text" placeholder="Digite aqui.." id="title">
            <span class="text-danger mt-1" v-if="errors.title">Este campo é obrigatório</span>
          </div>
          <div class="col-md-12 pb-3">
            <label for="slug" class="form-control-label">Url Callback:</label>
            <input type="text" v-model="data.url_callback" id="slug" placeholder="Digite aqui..." class="form-control">
          </div>
          <div class="col-md-12 pb-3">
            <label for="slug" class="form-control-label">Url Envio de E-mail:</label>
            <input type="text" v-model="data.url_send_email" id="slug" placeholder="Digite aqui..." class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Imagem</h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="row align-items-center">
              <div class="col-md-6">
                <h3 class="text-white">Imagem</h3>
              </div>
              <div class="col-md-6 text-right">
                <button v-if="img" class="btn btn-warning text-uppercase px-3 py-3" @click="crop()">
                  CORTAR
                </button>
                <input v-else type="file" ref="file" class="form-control" @change="uploadImage($event)" accept="image/*">
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 mt-3">
                    <img :src="data.avatar" class="w-100" alt="">
                  </div>
                  <div v-if="img" class="col-md-3 mt-3">
                    <Cropper
                      :restrictions="pixelsRestriction"
                      :src="img"
                      ref="cropper"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" v-if="!img" class="btn btn-success mt-3">
      Salvar Alterações
    </button>
  </form>
  </section>

</template>

<script>
import { mapState } from 'vuex'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {

  props: ['_preferences'],

  components: {
    Cropper
  },

  watch: {
    _preferences () {
      this.data = {
        ...this.data,
        ...this._preferences
      }
    }
  },

  data () {
    return {
      data: {
        installments: null,
        type: null,
        status: null,
        free_shipping: null
      },
      errors: {},
      img: null
    }
  },

  computed: mapState({
    preferences: state => state.params.data.preferencess,
    loading: state => state.params.loading
  }),

  methods: {

    pixelsRestriction ({ minWidth, minHeight, maxWidth, maxHeight, imageWidth, imageHeight }) {
      this.errorImage = null

      // se a imagem for menor que 200px
      if (imageHeight < 200 || imageWidth < 200) {
        this.errorImage = 'Essa imagem é muito pequena. Para melhor visualização do produto, opte por imagens com dimensões superiores a 600x600 px'
        return false

      // se a imagem for maior que 200px e menor que 600px
      } else if (imageHeight < 600 || imageWidth < 600) {
        this.errorImage = 'Dica: Para melhor visualização do produto, opte por imagens com dimensões superiores a 600px x 600px'
        return {
          minWidth: minWidth,
          minHeight: minHeight,
          maxWidth: Math.min(imageWidth, maxWidth),
          maxHeight: Math.min(imageHeight, maxHeight)
        }
      }
    },

    uploadImage (event) {
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.img = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    crop () {
      const { coordinates, canvas } = this.$refs.cropper.getResult()
      this.coordinates = coordinates
      // You able to do different manipulations at a canvas
      // but there we just get a cropped image
      this.data.avatar = canvas.toDataURL()
      this.img = null
    },

    save () {
      this.$emit('update', { id: this.$route.params.id, data: this.data })
    },

    deleteImageInArray (index) {
      this.data.images.splice(index, 1)
    }

  },

  mounted () {
    console.log('aqui')
    this.data = {
      ...this.data,
      ...this._preferences
    }
  }

}
</script>
