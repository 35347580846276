import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import LoginPage from '../views/auth/LoginPage.vue'
import ResetPasswordPage from '../views/auth/ResetPasswordPage.vue'

import IndexProductsPage from '../views/products/IndexProductsPage.vue'
import CreateProductsPage from '../views/products/CreateProductsPage.vue'
import ShowProductsPage from '../views/products/ShowProductsPage.vue'

import IndexRecurrencesPage from '../views/recurrences/IndexRecurrencesPage.vue'

import IndexCategoriesPage from '../views/categories/IndexCategoriesPage.vue'
import CreateCategoriesBlogPage from '../views/categories/CreateCategoriesBlogPage.vue'

import IndexColorsPage from '../views/colors/IndexColorsPage.vue'
import IndexMaterialsPage from '../views/materials/IndexMaterialsPage.vue'
import IndexSizesPage from '../views/sizes/IndexSizesPage.vue'

import IndexSessionsPage from '../views/sessions/IndexSessionsPage.vue'

import IndexCouponsPage from '../views/coupons/IndexCouponsPage.vue'
import ShowCouponsPage from '../views/coupons/ShowCouponsPage.vue'

import IndexIntegrationsPage from '../views/integrations/IndexIntegrationsPage.vue'

import IndexSuppliersPage from '../views/suppliers/IndexSuppliersPage.vue'
import CreateSuppliersPage from '../views/suppliers/CreateSuppliersPage.vue'
import ShowSuppliersPage from '../views/suppliers/ShowSuppliersPage.vue'

import IndexBankAccountsPage from '../views/bank-accounts/IndexBankAccountsPage.vue'
import CreateBankAccountsPage from '../views/bank-accounts/CreateBankAccountsPage.vue'
import ShowBankAccountsPage from '../views/bank-accounts/ShowBankAccountsPage.vue'

import IndexOrderPage from '../views/orders/IndexOrderPage.vue'
import ShowOrderPage from '../views/orders/ShowOrderPage.vue'

import IndexBlogPage from '../views/blog/IndexBlogPage.vue'
import CreateBlogPage from '../views/blog/CreateBlogPage.vue'

import IndexCustomerPage from '../views/customers/IndexCustomerPage.vue'
import ShowCustomerPage from '../views/customers/ShowCustomerPage.vue'

import IndexUsersPage from '../views/users/IndexUsersPage.vue'

import ShowPreferencesPage from '../views/preferences/ShowPreferencesPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Login',
      requiresAuth: false
    }
  },
  {
    path: '/reset-password',
    name: 'Redefinir Sehna',
    component: ResetPasswordPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Redefinir Sehna',
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Home',
      requiresAuth: true
    }
  },
  {
    path: '/products',
    name: 'Produtos',
    component: IndexProductsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Produtos',
      requiresAuth: true
    }
  },
  {
    path: '/products/create',
    name: 'Adicionar Produto',
    component: CreateProductsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Adicionar Produto',
      requiresAuth: true
    }
  },
  {
    path: '/products/:id',
    name: 'Editar Produto',
    component: ShowProductsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Editar Produto',
      requiresAuth: true
    }
  },
  {
    path: '/recurrences',
    name: 'Recorrências',
    component: IndexRecurrencesPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Recorrências',
      requiresAuth: true
    }
  },
  {
    path: '/coupons',
    name: 'Cupons',
    component: IndexCouponsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Cupons',
      requiresAuth: true
    }
  },
  {
    path: '/coupons/:id',
    name: 'Editar Cupom',
    component: ShowCouponsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Editar Cupom',
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    name: 'Categorias',
    component: IndexCategoriesPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Categorias',
      requiresAuth: true
    }
  },
  {
    path: '/sessions',
    name: 'Sessões',
    component: IndexSessionsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Sessões',
      requiresAuth: true
    }
  },
  {
    path: '/integrations',
    name: 'Integrações',
    component: IndexIntegrationsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Integrações',
      requiresAuth: true
    }
  },
  {
    path: '/suppliers',
    name: 'Fornecedores',
    component: IndexSuppliersPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Fornecedores',
      requiresAuth: true
    }
  },
  {
    path: '/suppliers/create',
    name: 'Adicionar Fornecedor',
    component: CreateSuppliersPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Adicionar Fornecedor',
      requiresAuth: true
    }
  },
  {
    path: '/suppliers/:id',
    name: 'Editar Fornecedor',
    component: ShowSuppliersPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Editar Fornecedor',
      requiresAuth: true
    }
  },
  {
    path: '/bank-accounts',
    name: 'Dados Bancários',
    component: IndexBankAccountsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Dados Bancários',
      requiresAuth: true
    }
  },
  {
    path: '/bank-accounts/create',
    name: 'Adicionar Dados Bancários',
    component: CreateBankAccountsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Adicionar Dados Bancários',
      requiresAuth: true
    }
  },
  {
    path: '/bank-accounts/:id',
    name: 'Editar Dados Bancários',
    component: ShowBankAccountsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Editar Dados Bancários',
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'Pedidos',
    component: IndexOrderPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Pedidos',
      requiresAuth: true
    }
  },
  {
    path: '/orders/:id',
    name: 'Detalhes do Pedido',
    component: ShowOrderPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Pedido',
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'Usuários',
    component: IndexUsersPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Usuários',
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    name: 'Clientes',
    component: IndexCustomerPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Clientes',
      requiresAuth: true
    }
  },
  {
    path: '/customers/:id',
    name: 'Detalhes do Cliente',
    component: ShowCustomerPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Cliente',
      requiresAuth: true
    }
  },
  {
    path: '/articles',
    name: 'Publicações',
    component: IndexBlogPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Publicações',
      requiresAuth: true
    }
  },
  {
    path: '/articles/:id',
    name: 'Artigo',
    component: CreateBlogPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Publicações',
      requiresAuth: true
    }
  },
  {
    path: '/articles/create',
    name: 'Nova Publicação',
    component: CreateBlogPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Nova Publicação',
      requiresAuth: true
    }
  },
  {
    path: '/categories-blog',
    name: 'Categorias Blog',
    component: CreateCategoriesBlogPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Categorias Blog',
      requiresAuth: true
    }
  },
  {
    path: '/colors',
    name: 'Cores de Produtos',
    component: IndexColorsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Cores de Produtos',
      requiresAuth: true
    }
  },
  {
    path: '/materials',
    name: 'Materiais de Produtos',
    component: IndexMaterialsPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Materiais de Produtos',
      requiresAuth: true
    }
  },
  {
    path: '/sizes',
    name: 'Tamanhos de Produtos',
    component: IndexSizesPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Tamanhos de Produtos',
      requiresAuth: true
    }
  },
  {
    path: '/preferences',
    name: 'Preferências',
    component: ShowPreferencesPage,
    meta: {
      title: process.env.VUE_APP_TITLE + ' - Preferências da Conta',
      requiresAuth: true
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.token) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
