import Vue from 'vue'
import { index, store, update, destroy } from '../../services/recurrences/recurrences'

export const recurrences = {

  namespaced: 'recurrences',

  state: {
    data: {},
    pagination: {},
    loading: false,
    error: null
  },

  actions: {

    index ({ state, commit }, data) {
      index(data.page, data.search, data.filter)
        .then(response => {
          commit('setData', response.data.recurrences)
          commit('setPagination', response.data.recurrences)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, dispatch, commit }, data) {
      store(data)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success('recorrência atualizada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar recorrência!')
        })
    },

    update ({ state, commit }, data) {
      update(data)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success('recorrência atualizada com sucesso!')
          setTimeout(() => {
            window.location.reload(1)
          }, 1000)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar recorrência!')
        })
    },

    destroy ({ state, commit }, id) {
      destroy(id)
        .then(response => {
          commit('setLoading', false)
          Vue.toasted.success('recorrência removida com sucesso!')
          setTimeout(() => {
            window.location.reload(1)
          }, 1000)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar recorrência!')
        })
    }
  },

  mutations: {

    setData (state, recurrence) {
      state.data = recurrence
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
