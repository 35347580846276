import Http from '../http'

export const index = (page, search) => {
  var url = `/articles?page=${page}`
  if (search) url = url + `&search=${search}`
  return Http.get(url, { headers: { Authorization: localStorage.token } })
}
export const show = (id) => Http.get(`/articles/${id}`, { headers: { Authorization: localStorage.token } })
export const store = (data) => Http.post('/articles', { data }, { headers: { Authorization: localStorage.token } })
export const update = (id, data) => Http.patch(`/articles/${id}`, { data }, { headers: { Authorization: localStorage.token } })
export const destroy = (id) => Http.delete(`/articles/${id}`, { headers: { Authorization: localStorage.token } })
