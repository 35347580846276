<template>
  <div class="materials">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Materiais dos Produtos</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Nova Material
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="material in materials.data" :key="material.id">
                <td class="align-middle body-table">
                    {{ material.id }}
                </td>
                <th class="align-middle body-table">
                    {{ material.description }}
                </th>
                <td class="align-middle body-table">
                  <button v-if="material.company_id" class="btn mr-2" data-toggle="modal" @click="openUpdateModal(material)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button v-if="material.company_id" class="btn" data-toggle="modal" @click="openDeleteModal(material)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :source="pagination" @navigate="navigate"/>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !materials.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (material.id ? 'Editar Material' : 'Cadastrar Material')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <label class="form-control-label">Nome:</label>
                <input type="text" class="form-control" v-model="material.description" required>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <label class="form-control-label">Descrição:</label>
                <textarea class="form-control" rows="5" v-model="material.text_description" required></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updatematerial()" v-if="material.id">Editar Material</button>
            <button type="button" class="btn btn-success" @click="addmaterial()" v-else>Cadastrar Material</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir Material</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      Deseja excluir o Material?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deletematerial()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexmaterialsPage',

  components: {
    PaginateComponent,
    LoadingComponent
  },

  computed: mapState({
    materials: state => state.materials.data,
    pagination: state => state.materials.pagination,
    loading: state => state.materials.loading
  }),

  data: function () {
    return {
      material: {
        description: null
      },
      destroy_material: null
    }
  },

  methods: {

    ...mapActions('materials', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search })
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    addmaterial: function () {
      this.store(this.material)
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.material = {
        description: null
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (material) {
      this.material = material
      $('#CreateModal').modal()
    },

    openDeleteModal: function (material) {
      this.destroy_material = material
      $('#openDeleteModal').modal()
    },

    updatematerial: function () {
      this.update({ id: this.material.id, data: this.material })
      $('#CreateModal').modal('hide')
    },

    deletematerial: function () {
      this.destroy(this.destroy_material.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
