import { index, store, update, destroy } from '../../services/users/users'
import Vue from 'vue'
export const users = {

  namespaced: 'users',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.users)
          commit('setPagination', response.data.users)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, data) {
      data.redirect = window.location.protocol + '//' + window.location.host + '/reset-password'
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.user)
          commit('setLoading', false)
          Vue.toasted.success('cor cadastrada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar a cor!')
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.user)
          commit('setLoading', false)
          Vue.toasted.success('cor editada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a cor!')
        })
    },

    destroy ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      destroy(data)
        .then(response => {
          commit('setDetails', response.data.user)
          commit('setLoading', false)
          Vue.toasted.success('cor excluída com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao excluir a cor!')
        })
    }

  },

  mutations: {

    setData (state, users) {
      state.data = users
    },

    setDetails (state, user) {
      state.details = user
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
