import { index, show, store, update, destroy } from '../../services/articles/articles'
import Vue from 'vue'
export const articles = {
  namespaced: 'articles',
  state: {
    data: {},
    details: {},
    pagination: {},
    loading: false,
    error: null
  },
  actions: {
    index ({ state, commit }, { page, search }) {
      index(page, search)
        .then(response => {
          commit('setData', response.data.articles)
          commit('setPagination', response.data.articles)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },
    show ({ state, commit }, id) {
      show(id)
        .then(response => {
          commit('setDetails', response.data.article)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },
    store ({ state, commit, dispatch }, data) {
      store(data)
        .then(response => {
          commit('setDetails', response.data.article)
          commit('setLoading', false)
          Vue.toasted.success('Artigo cadastrado com sucesso!')
          dispatch('index', { page: 1, search: '' })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar artigo!')
          dispatch('index', { page: 1, search: '' })
        })
    },
    update ({ state, commit, dispatch }, { id, data, pagination }) {
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.article)
          commit('setLoading', false)
          Vue.toasted.success('Artigo atualizado com sucesso!')
          dispatch('index', { page: pagination, search: '' })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar artigo!')
          dispatch('index', { page: 1, search: '' })
        })
    },
    destroy ({ state, commit, dispatch }, data) {
      destroy(data)
        .then(response => {
          commit('setData', response.data.article)
          commit('setLoading', false)
          Vue.toasted.success('Artigo excluído com sucesso!')
          dispatch('index', { page: 1, search: '' })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.success('Erro ao excluir artigo')
          dispatch('index', { page: 1, search: '' })
        })
    }
  },

  mutations: {

    setData (state, articles) {
      state.data = articles
    },

    setDetails (state, article) {
      state.details = article
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
