<template>
  <div class="variants">
    <div class="card my-5">
      <div class="card-header bg-transparent">
        <h6 class="mb-0">Variações</h6>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>SKU</th>
                <th>Cor</th>
                <th>Tamanho</th>
                <th>Material</th>
                <th>Preço</th>
                <th>Quantidade</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="variant in variants.data" :key="variant.id">
                <td>{{ variant.id }}</td>
                <td>{{ variant.description }}</td>
                <td>{{ variant.sku_code }}</td>
                <td>{{ variant.color ? variant.color.description : ' - ' }}</td>
                <td>{{ variant.size ? variant.size.description : ' - ' }}</td>
                <td>{{ variant.material ? variant.material.description : ' - ' }}</td>
                <td>{{ variant.price | money }}</td>
                <td>{{ variant.quantity }}</td>
                <td>
                  <button type="button" class="btn mr-2" data-toggle="modal" @click="openUpdateModal(variant)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button type="button" class="btn" data-toggle="modal" @click="openDeleteModal(variant)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :source="pagination" @navigate="navigate"/>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !variants.data" />
    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (variant.id ? 'Editar Variação' : 'Cadastrar Variação')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="variant.description">
                  </div>
                  <div class="col">
                    <label class="form-control-label">SKU:</label>
                    <input type="text" class="form-control" v-model="variant.sku_code">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <label class="form-control-label">Cor:</label>
                    <select class="form-control" v-model="variant.param_color_id">
                      <option :value="null"> - Selecione - </option>
                      <option v-for="color in colors" :key="color.id" :value="color.id">{{ color.description }}</option>
                    </select>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Tamanho:</label>
                    <select class="form-control" v-model="variant.param_size_id">
                      <option :value="null"> - Selecione - </option>
                      <option v-for="size in sizes" :key="size.id" :value="size.id">{{ size.description }}</option>
                    </select>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Material:</label>
                    <select class="form-control" v-model="variant.param_material_id">
                      <option :value="null"> - Selecione - </option>
                      <option v-for="material in materials" :key="material.id" :value="material.id">{{ material.description }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <div class="input-group">
                    <label for="price" class="form-control-label w-100">Preço:</label>
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="price-addon">R$</span>
                      </div>
                      <money class="form-control weight" id="weight" v-model="variant.price" v-bind="money"></money>
                    </div>
                  </div>
                  <div class="col">
                    <label class="form-control-label">Quantidade:</label>
                    <input type="text" class="form-control" v-model="variant.quantity">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateVariant()" v-if="variant.id">Editar Variação</button>
            <button type="button" class="btn btn-success" @click="addVariant()" v-else>Cadastrar Variação</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
    <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">Excluir Variação</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Deseja excluir o Variação?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                    <button type="button" class="btn btn-danger" @click="deleteVariant()">Excluir</button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexvariantsPage',

  props: ['id'],

  components: {
    PaginateComponent,
    LoadingComponent
  },

  computed: mapState({
    variants: state => state.variants.data,
    pagination: state => state.variants.pagination,
    loading: state => state.variants.loading,
    colors: state => state.params.data.colors,
    materials: state => state.params.data.materials,
    sizes: state => state.params.data.sizes
  }),

  data () {
    return {
      variant: {
        description: null,
        sku_code: null,
        param_color_id: null,
        param_size_id: null,
        param_material_id: null,
        price: null,
        quantity: null
      },
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      destroy_variant: null
    }
  },

  methods: {

    ...mapActions('variants', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    ...mapActions('params', [
      'getColors',
      'getMaterials',
      'getSizes'
    ]),

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search, product: this.id })
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    addVariant () {
      this.store(this.variant)
      $('#CreateModal').modal('hide')
    },

    openCreateModal () {
      this.variant = {
        description: null,
        sku_code: null,
        param_color_id: null,
        param_size_id: null,
        param_material_id: null,
        price: null,
        quantity: null
      }
      $('#CreateModal').modal()
    },

    openUpdateModal (variant) {
      this.variant = variant
      $('#CreateModal').modal()
    },

    openDeleteModal (variant) {
      this.destroy_variant = variant
      $('#openDeleteModal').modal()
    },

    updateVariant () {
      this.update({ id: this.variant.id, product: this.id, data: this.variant })
      $('#CreateModal').modal('hide')
    },

    deleteVariant () {
      this.destroy({ product: this.id, id: this.destroy_variant.id })
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null,
      product: this.id
    })
    this.getColors()
    this.getMaterials()
    this.getSizes()
  }

}

</script>
