import { index, store, update, destroy } from '../../services/materials/materials'
import Vue from 'vue'
export const materials = {

  namespaced: 'materials',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: true,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.materials)
          commit('setPagination', response.data.materials)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.material)
          commit('setLoading', false)
          Vue.toasted.success('material cadastrada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao cadastrar a material!')
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.material)
          commit('setLoading', false)
          Vue.toasted.success('material editada com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao editar a material!')
        })
    },

    destroy ({ state, commit, dispatch }, data) {
      commit('setLoading', true)
      destroy(data)
        .then(response => {
          commit('setDetails', response.data.material)
          commit('setLoading', false)
          Vue.toasted.success('material excluída com sucesso!')
          dispatch('index', { page: 1 })
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao excluir a material!')
        })
    }

  },

  mutations: {

    setData (state, materials) {
      state.data = materials
    },

    setDetails (state, material) {
      state.details = material
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
