<template>
  <div class="customer">
    <div class="row mb-4">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <p class="fs-24 fw-600 c-black mb-0"> Cliente</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3 mt-md-0 details">
        <div class="card py-4">
          <div class="row">
            <div class="col-12">
              <h4>Detalhes do cliente</h4>
            </div>
            <div class="col-12 pb-3">
              <p>Nome</p>
              <h3>{{ customer.name }}</h3>
            </div>
            <div class="col-md-4 pb-3">
              <p>CPF/CNPJ</p>
              <h5>{{customer.details.tax_document_number}}</h5>
            </div>
            <div class="col-md-4 pb-3">
              <p>Data de Nascimento</p>
              <h5>{{customer.details.birthdate}}</h5>
            </div>
            <div class="col-md-4 pb-3">
              <p>Telefone</p>
              <h5>{{'(' + customer.details.phone_area_code + ')'}} {{customer.details.phone_number}}</h5>
            </div>
            <div class="col-12">
              <p>E-mail</p>
              <h5>{{customer.email}}</h5>
            </div>
          </div>
          <hr>
          <div class="row align-items-center border-bottom" @click="updateAddress(address.id)" v-for="address in customer.addresses" :key="address.id">
            <div class="col-md">
              <div class="d-flex align-items-center py-3">
                <svg class="pointer" v-if="customer.address_active == address.id" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1 -1 20 20" height="20" width="20" id="Check-Square--Streamline-Core.svg"><desc>Check Square Streamline Icon: https://streamlinehq.com</desc><g id="Check-Square--Streamline-Core.svg"><path id="Vector" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" d="M13.500000000000002 0.6428571428571429h-9c-2.1302357142857145 0 -3.8571428571428577 1.726907142857143 -3.8571428571428577 3.8571428571428577v9c0 2.1303 1.726907142857143 3.8571428571428577 3.8571428571428577 3.8571428571428577h9c2.1303 0 3.8571428571428577 -1.7268428571428571 3.8571428571428577 -3.8571428571428577v-9c0 -2.1302357142857145 -1.7268428571428571 -3.8571428571428577 -3.8571428571428577 -3.8571428571428577Z" stroke-width="2"></path><path id="Vector_2" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" d="m12.75057 6.107142857142858 -5.142857142857143 6.428571428571429 -2.5714285714285716 -1.9285714285714288" stroke-width="2"></path></g></svg>
                <svg class="pointer" v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-1 -1 20 20" height="20" width="20" id="Button-Stop--Streamline-Core.svg"><desc>Button Stop Streamline Icon: https://streamlinehq.com</desc><g id="Button-Stop--Streamline-Core.svg"><path id="Vector" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" d="M16.071428571428573 0.6428571428571429h-14.142857142857144c-0.7100807142857144 0 -1.2857142857142858 0.5756335714285714 -1.2857142857142858 1.2857142857142858v14.142857142857144c0 0.7101000000000001 0.5756335714285714 1.2857142857142858 1.2857142857142858 1.2857142857142858h14.142857142857144c0.7101000000000001 0 1.2857142857142858 -0.5756142857142857 1.2857142857142858 -1.2857142857142858v-14.142857142857144c0 -0.7100807142857144 -0.5756142857142857 -1.2857142857142858 -1.2857142857142858 -1.2857142857142858Z" stroke-width="2"></path></g></svg>
                <div class="d-block ml-4">
                  <p class="mb-0"><b>Endereço</b></p>
                  <p class="mb-0">{{address.street}}, {{address.street_number}} - {{ address.complement ? address.complement + ' - ' : '' }}{{address.district}} - {{address.city}} - {{address.state}} - {{address.zipcode}}</p>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !customer.id || logLoading" />
  </div>

</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'ShowcustomerPage',

  components: {
    LoadingComponent
  },

  data () {
    return {
      customer: {
        details: {},
        addresses: []
      }
    }
  },

  watch: {
    details () {
      this.customer = {
        ...this.details
      }
    }
  },

  computed: mapState({
    details: state => state.customers.details,
    logLoading: state => state.logs.loading,
    loading: state => state.customers.loading
  }),

  methods: {

    ...mapActions('customers', [
      'show',
      'update'
    ]),

    updateAddress (id) {
      this.customer.address_active = id
      this.update(this.customer)
    }

  },

  mounted () {
    this.show(this.$route.params.id)
  }
}

</script>

<style scoped>
  .white {
    color: white;
  }
</style>
