<template>
    <div class="card mb-3 w-100">
      <div class="card-body w-100">
        <Payments :payment_status="payments"/>
        <p><b>{{ payments[0] ? payments[0].param_payment_type_id : '' | payment_type }}</b></p>
        <p>Parcelas: {{payments[0].installments}}</p>
        <p v-if="tax > 0">Taxa: {{ tax | money }}</p>
        <p>Total: {{ amount | money }}</p>
        <p v-if="order.coupon_text != null">Cupom: {{ order.coupon_text }}</p>
        <p v-if="cupom">Cupom: {{ cupom }}</p>
        <p v-if="payments[0].payment_on_delivery"><b>Pagamento na entrega</b></p>
        <p v-if="payments[0].payment_change > 0">Troco para: {{ payments[0].payment_change | money }}</p>
        <p v-if="payments[0].payment_number && payments[0].visible" class="my-3 py-0">
          {{ payments[0].payment_number }}
        </p>
        <a v-if="payments[0].payment_url && payments[0].visible" :href="payments[0].payment_url" target="_blank" class="btn-link btn-sm my-0 px-0 py-0">
          <small>Ver Arquivo</small>
        </a>
      </div>
    </div>
</template>

<script>
import PaymentStatusComponent from '../components/PaymentStatusComponent.vue'

export default {

  components: {
    Payments: PaymentStatusComponent
  },

  props: ['payments', 'cupom', 'amount', 'order', 'tax'],

  data () {
    return {
      payment_detail: {}
    }
  }
}
</script>

<style scoped>
    .my-card-title {
        font-size: 23px !important;
    }
</style>
