<template>
  <div class="blogs">
    <div class="row align-items-center justify-content-between mx-0 mb-3">
      <h1 class="fw-600 fs-24 mb-0">Categorias - Blog</h1>
      <button class="btn btn-blue" @click="openCreateModal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M9.99999 15.8334V4.16675M15.8333 10.0001H4.16666" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
        Nova Categoria
      </button>
    </div>

    <div class="breadcrumbs mb-4">
      <div class="card">
        <button class="btn btn-home" @click="index({page: 1, name: null})">
          <svg xmlns="http://www.w3.org/2000/svg" class="d-block" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M2 5.99992L8 1.33325L14 5.99992V13.3333C14 13.6869 13.8595 14.026 13.6095 14.2761C13.3594 14.5261 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5261 2.39052 14.2761C2.14048 14.026 2 13.6869 2 13.3333V5.99992Z" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 14.6667V8H10V14.6667" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <div class="category pointer ml-2" v-for="breadcrumb in breadcrumbs" :key="breadcrumb.id">
          <p class="mb-0">{{ breadcrumb.description }}</p>
        </div>
        <div class="category pointer ml-2" v-if="breadcrumbs.length >= 1" @click="breadcrumbs[breadcrumbs.length - 1].parent_id ? back(breadcrumbs[breadcrumbs.length - 1].parent_id) : index({page: 1, name: null})">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clip-path="url(#clip0_85_603)">
            <path d="M7.89797 4.89797L6.79688 6L7.89938 7.10203C8.00505 7.2077 8.06442 7.35103 8.06442 7.50047C8.06442 7.64991 8.00505 7.79323 7.89938 7.89891C7.7937 8.00458 7.65038 8.06394 7.50094 8.06394C7.3515 8.06394 7.20817 8.00458 7.1025 7.89891L6 6.79688L4.89797 7.89937C4.7923 8.00505 4.64898 8.06441 4.49953 8.06441C4.35009 8.06441 4.20677 8.00505 4.1011 7.89937C3.99542 7.7937 3.93606 7.65038 3.93606 7.50094C3.93606 7.35149 3.99542 7.20817 4.1011 7.1025L5.20313 6L4.10203 4.89797C4.04971 4.84565 4.0082 4.78353 3.97989 4.71516C3.95157 4.6468 3.937 4.57353 3.937 4.49953C3.937 4.35009 3.99636 4.20677 4.10203 4.10109C4.20771 3.99542 4.35103 3.93606 4.50047 3.93606C4.64991 3.93606 4.79324 3.99542 4.89891 4.10109L6 5.20312L7.10203 4.10063C7.20771 3.99495 7.35103 3.93559 7.50047 3.93559C7.64991 3.93559 7.79324 3.99495 7.89891 4.10063C8.00458 4.2063 8.06395 4.34962 8.06395 4.49906C8.06395 4.64851 8.00458 4.79183 7.89891 4.8975L7.89797 4.89797ZM11.0625 6C11.0625 7.00127 10.7656 7.98005 10.2093 8.81257C9.65304 9.6451 8.86239 10.294 7.93734 10.6771C7.01229 11.0603 5.99439 11.1606 5.01236 10.9652C4.03033 10.7699 3.12828 10.2877 2.42027 9.57973C1.71227 8.87173 1.23011 7.96967 1.03478 6.98764C0.839439 6.00562 0.939694 4.98772 1.32286 4.06266C1.70603 3.13761 2.3549 2.34696 3.18743 1.79069C4.01995 1.23441 4.99873 0.9375 6 0.9375C7.3422 0.938989 8.62901 1.47284 9.57809 2.42192C10.5272 3.371 11.061 4.6578 11.0625 6ZM9.9375 6C9.9375 5.22124 9.70657 4.45996 9.27391 3.81244C8.84126 3.16492 8.2263 2.66024 7.50682 2.36222C6.78733 2.0642 5.99563 1.98623 5.23183 2.13816C4.46803 2.29009 3.76644 2.6651 3.21577 3.21577C2.6651 3.76644 2.29009 4.46803 2.13816 5.23183C1.98623 5.99563 2.06421 6.78733 2.36223 7.50682C2.66025 8.2263 3.16493 8.84125 3.81244 9.27391C4.45996 9.70657 5.22124 9.9375 6 9.9375C7.04395 9.93638 8.04482 9.52118 8.783 8.783C9.52118 8.04482 9.93639 7.04395 9.9375 6Z" fill="#0062FF"/>
            </g>
            <defs>
            <clipPath id="clip0_85_603">
            <rect width="12" height="12" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>

    <div class="categories">
      <div class="categories-box pointer" v-for="category in categories" :key="category.id" @click="show(category.id)">
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h6 class="fw-600 fs-16 mb-0">{{ category.description }}</h6>
              <!-- <p class="fw-400 fs-12 c-grey ml-4 mb-0">{{ category.acessos }} {{ category.acessos > 1 ? ' acessos' : 'acesso' }}</p> -->
            </div>
            <div class="d-flex align-items-center">

              <!-- Editar categoria -->
              <button class="btn btn-2 mx-2" @click="openUpdateModal(category)" @click.stop>
                <svg xmlns="http://www.w3.org/2000/svg" class="d-block" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <g clip-path="url(#clip0_52_2875)">
                  <path d="M7.42604 2.6665H2.75938C2.40576 2.6665 2.06662 2.80698 1.81657 3.05703C1.56652 3.30708 1.42604 3.64622 1.42604 3.99984V13.3332C1.42604 13.6868 1.56652 14.0259 1.81657 14.276C2.06662 14.526 2.40576 14.6665 2.75938 14.6665H12.0927C12.4463 14.6665 12.7855 14.526 13.0355 14.276C13.2856 14.0259 13.426 13.6868 13.426 13.3332V8.6665" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.426 1.66665C12.6913 1.40144 13.051 1.25244 13.426 1.25244C13.8011 1.25244 14.1608 1.40144 14.426 1.66665C14.6913 1.93187 14.8403 2.29158 14.8403 2.66665C14.8403 3.04173 14.6913 3.40144 14.426 3.66665L8.09271 9.99999L5.42604 10.6667L6.09271 7.99999L12.426 1.66665Z" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_52_2875">
                  <rect width="16" height="16" fill="white" transform="translate(0.0927124)"/>
                  </clipPath>
                  </defs>
                </svg>
              </button>

              <!-- Excluir categoria -->
              <button class="btn btn-2" @click="openDeleteModal(category)" @click.stop>
              <!-- <button class="btn btn-2" data-toggle="modal" data-target="#deleteCategoryModal"> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="d-block" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path d="M2.09271 4H3.42605H14.0927" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.7594 4.00016V13.3335C12.7594 13.6871 12.6189 14.0263 12.3689 14.2763C12.1188 14.5264 11.7797 14.6668 11.426 14.6668H4.75938C4.40576 14.6668 4.06662 14.5264 3.81657 14.2763C3.56652 14.0263 3.42605 13.6871 3.42605 13.3335V4.00016M5.42605 4.00016V2.66683C5.42605 2.31321 5.56652 1.97407 5.81657 1.72402C6.06662 1.47397 6.40576 1.3335 6.75938 1.3335H9.42605C9.77967 1.3335 10.1188 1.47397 10.3689 1.72402C10.6189 1.97407 10.7594 2.31321 10.7594 2.66683V4.00016" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading" />

    <!-- Create Category Modal -->
    <div class="modal fade" id="createCategoryModal" tabindex="-1" role="dialog" aria-labelledby="createCategoryModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content modal-category">
          <div class="d-flex align-items-center justify-content-between border-bottom">
            <h5 class="fw-600 fs-16 mb-0" id="blockModalTitle">{{ category.id ? 'Editar categoria' : 'Criar categoria' }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <!-- <span aria-hidden="true">&times;</span> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.71995 3.72006C3.86058 3.57961 4.0512 3.50072 4.24995 3.50072C4.4487 3.50072 4.63933 3.57961 4.77995 3.72006L7.99995 6.94006L11.22 3.72006C11.3116 3.62133 11.4282 3.54916 11.5575 3.51115C11.6867 3.47313 11.8238 3.47068 11.9544 3.50406C12.0849 3.53743 12.204 3.60539 12.2991 3.70078C12.3943 3.79617 12.4619 3.91545 12.495 4.04606C12.5284 4.17643 12.526 4.31341 12.4882 4.44257C12.4504 4.57173 12.3784 4.68832 12.28 4.78006L9.05995 8.00006L12.28 11.2201C12.3787 11.3117 12.4508 11.4283 12.4889 11.5576C12.5269 11.6868 12.5293 11.8239 12.496 11.9545C12.4626 12.085 12.3946 12.2041 12.2992 12.2992C12.2038 12.3944 12.0846 12.462 11.954 12.4951C11.8236 12.5285 11.6866 12.5261 11.5574 12.4883C11.4283 12.4505 11.3117 12.3785 11.22 12.2801L7.99995 9.06006L4.77995 12.2801C4.63769 12.4125 4.44964 12.4846 4.25532 12.4812C4.061 12.4779 3.87555 12.3993 3.73795 12.2621C3.60071 12.1245 3.52215 11.939 3.5188 11.7447C3.51544 11.5504 3.58754 11.3623 3.71995 11.2201L6.93995 8.00006L3.71995 4.78006C3.5795 4.63943 3.50061 4.44881 3.50061 4.25006C3.50061 4.05131 3.5795 3.86068 3.71995 3.72006Z" fill="#171725"/>
              </svg>
            </button>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <label for="">Nome*</label>
              <input type="text" placeholder="Nome da Categoria" v-model="category.description">
              <button class="btn btn-blue mt-2 mx-auto" @click=" category.id ? updateCategory() : addCategory()">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g clip-path="url(#clip0_1_1170)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.9549 4.25916C18.1893 4.49357 18.3209 4.81146 18.3209 5.14291C18.3209 5.47437 18.1893 5.79225 17.9549 6.02666L8.58577 15.3958C8.46196 15.5197 8.31496 15.6179 8.15317 15.6849C7.99139 15.752 7.81798 15.7865 7.64286 15.7865C7.46774 15.7865 7.29433 15.752 7.13254 15.6849C6.97076 15.6179 6.82376 15.5197 6.69994 15.3958L2.04494 10.7417C1.92555 10.6264 1.83032 10.4884 1.76481 10.3359C1.6993 10.1834 1.66482 10.0194 1.66338 9.85341C1.66194 9.68744 1.69356 9.52284 1.75641 9.36922C1.81926 9.2156 1.91208 9.07603 2.02945 8.95867C2.14681 8.8413 2.28638 8.74849 2.44 8.68563C2.59362 8.62278 2.75822 8.59116 2.92419 8.5926C3.09017 8.59404 3.25419 8.62852 3.4067 8.69403C3.5592 8.75955 3.69713 8.85477 3.81244 8.97416L7.64244 12.8042L16.1866 4.25916C16.3027 4.143 16.4405 4.05086 16.5922 3.98799C16.7439 3.92512 16.9066 3.89276 17.0708 3.89276C17.235 3.89276 17.3976 3.92512 17.5493 3.98799C17.701 4.05086 17.8389 4.143 17.9549 4.25916Z" fill="white"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1_1170">
                  <rect width="20" height="20" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
                Salvar Categoria
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete Category Modal -->
    <div class="modal fade" id="deleteCategoryModal" tabindex="-1" role="dialog" aria-labelledby="deleteCategoryModalTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content modal-category">
          <div class="d-flex align-items-center justify-content-between border-bottom">
            <h5 class="fw-600 fs-16 mb-0" id="blockModalTitle">Deseja mesmo excluir este item?</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <!-- <span aria-hidden="true">&times;</span> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M3.71995 3.72006C3.86058 3.57961 4.0512 3.50072 4.24995 3.50072C4.4487 3.50072 4.63933 3.57961 4.77995 3.72006L7.99995 6.94006L11.22 3.72006C11.3116 3.62133 11.4282 3.54916 11.5575 3.51115C11.6867 3.47313 11.8238 3.47068 11.9544 3.50406C12.0849 3.53743 12.204 3.60539 12.2991 3.70078C12.3943 3.79617 12.4619 3.91545 12.495 4.04606C12.5284 4.17643 12.526 4.31341 12.4882 4.44257C12.4504 4.57173 12.3784 4.68832 12.28 4.78006L9.05995 8.00006L12.28 11.2201C12.3787 11.3117 12.4508 11.4283 12.4889 11.5576C12.5269 11.6868 12.5293 11.8239 12.496 11.9545C12.4626 12.085 12.3946 12.2041 12.2992 12.2992C12.2038 12.3944 12.0846 12.462 11.954 12.4951C11.8236 12.5285 11.6866 12.5261 11.5574 12.4883C11.4283 12.4505 11.3117 12.3785 11.22 12.2801L7.99995 9.06006L4.77995 12.2801C4.63769 12.4125 4.44964 12.4846 4.25532 12.4812C4.061 12.4779 3.87555 12.3993 3.73795 12.2621C3.60071 12.1245 3.52215 11.939 3.5188 11.7447C3.51544 11.5504 3.58754 11.3623 3.71995 11.2201L6.93995 8.00006L3.71995 4.78006C3.5795 4.63943 3.50061 4.44881 3.50061 4.25006C3.50061 4.05131 3.5795 3.86068 3.71995 3.72006Z" fill="#171725"/>
              </svg>
            </button>
          </div>
          <div class="row">
            <div class="col-12 mt-2 mb-4">
              <p class="fw-400 fs-14 mb-0 text-center">Ele não poderá ser recuperado.</p>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center">
              <button class="btn btn-outline-red" data-dismiss="modal">Cancelar</button>
              <button class="btn btn-red ml-3" @click="deleteCategory()">Excluir</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'CreateCategoriesBlogPage',

  components: {
    LoadingComponent
  },

  computed: mapState({
    categories: state => state.blogCategories.data,
    currentCategory: state => state.blogCategories.details,
    breadcrumbs: state => state.blogCategories.breadcrumbs,
    loading: state => state.blogCategories.loading
  }),

  data: function () {
    return {
      category: {}
    }
  },

  methods: {

    ...mapActions('blogCategories', [
      'index',
      'store',
      'update',
      'destroy',
      'show',
      'back'
    ]),

    addCategory: function () {
      this.store(this.category)
      $('#createCategoryModal').modal('hide')
    },
    openCreateModal: function () {
      this.category = {
        description: null,
        parent_id: this.currentCategory.id ? this.currentCategory.id : null
      }
      $('#createCategoryModal').modal('show')
    },

    openUpdateModal: function (category) {
      this.category = category
      $('#createCategoryModal').modal('show')
    },
    updateCategory: function () {
      this.update({ id: this.category.id, data: this.category })
      $('#createCategoryModal').modal('hide')
    },

    openDeleteModal: function (category) {
      this.destroy_category = category
      $('#deleteCategoryModal').modal('show')
    },
    deleteCategory: function () {
      this.destroy(this.destroy_category.id)
      $('#deleteCategoryModal').modal('hide')
    }

  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }
}
</script>

<style scoped>
.modal-content.modal-category {
  margin-top: 0 !important;
  border-radius: 5px;
  border: 0;
  padding: 16px;
}

    /* font-size: 28px; */
  input, select {
    font-weight: 600;
    border: 0;
    color: #000000;
    width: 100%;
  }
  input.type-1 {
    border-radius: 4px;
    border: 1px solid #E2E2EA;
    color: #696974 !important;
    font-size: 14px;
    font-weight: 400;
    padding: 4px 24px 4px 8px;
  }
  input::placeholder {
    color: #000000;
  }
  .card-title {
    font-weight: 600;
    font-size: 16px;
  }
  label {
    display: block;
    color: #696974;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .breadcrumbs .card {
    border-radius: 10px;
    border: 1px solid #E2E2EA;
    background: #FFF;
    padding: 32px;
    display: flex;
    flex-direction: row;
  }

  .btn-blue {
    padding: 3px 8px;
    font-size: 12px;
    white-space: nowrap;
  }
  .btn-home {
    /* border-radius: 5px 5px 0px 0px; */
    background: #0062ff1a;
    padding: 0;
    height: 26px;
  }
  .btn-home svg {
    margin: 5px;
  }
  .category {
    display: flex;
    align-items: center;
    height: 26px;
    color: #0062FF;
    padding: 4px 8px;
    background-color: #0062ff0d;
    margin-bottom: 0;
    border-radius: 5px;
  }
.categories .categories-box {
  /* border-radius: 20px; */
  border: 1px solid #E2E2EA;
  background: #FFF;
  padding: 32px;
}
.categories .categories-box:first-child {
  /* border-radius: 10px; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.categories .categories-box:last-child {
  /* border-radius: 10px; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.btn-1 {
  border-radius: 5px;
  background: #0062FF;
  padding: 4px;
}
.btn-2 {
  border-radius: 5px;
  background: #0062ff1a;
  padding: 4px;
}
.btn-red {
  width: 95px;
  height: 38px;
  padding: 8px 14px;
  border-radius: 10px;
  background: #FC5A5A;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}
.btn-outline-red {
  width: 95px;
  height: 38px;
  padding: 8px 14px;
  border-radius: 10px;
  background: transparent;
  color: #FC5A5A;
  border: 1px solid #FC5A5A;
  font-weight: 600;
  font-size: 14px;
}
  .btn-red:hover {
    color: #ffffff;
  }
  .btn-outline-red:hover {
    color: #FC5A5A;
  }
</style>
