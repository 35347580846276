import Vue from 'vue'
import { getTheme, show, update } from '../../services/preferences/preferences'

export const preferences = {

  namespaced: 'preferences',

  state: {
    data: {},
    loading: false,
    error: null
  },

  actions: {

    getTheme ({ state, commit }) {
      commit('setLoading', true)
      getTheme()
        .then(response => {
          commit('setTheme', response.data.theme)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response.message)
          commit('setLoading', false)
        })
    },

    show ({ state, commit }) {
      commit('setLoading', true)
      show()
        .then(response => {
          commit('setPreferences', response.data.preferences)
        })
        .catch(error => {
          commit('setError', error.response.message)
        })
    },

    update ({ state, commit }, data) {
      commit('setLoading', true)
      update(data)
        .then(response => {
          commit('setPreferences', response.data.preferences)
          commit('setLoading', false)
          Vue.toasted.success('Preferências atualizadas com sucesso!')
        })
        .catch(error => {
          commit('setError', error.response.message)
        })
    }

  },

  mutations: {

    setTheme (state, theme) {
      document.title = theme.title
      state.data = theme
    },

    setPreferences (state, data) {
      state.data = {
        ...state.data,
        ...data
      }
    },

    setLoading (state, status) {
      state.loading = status
    }

  },

  getters: {}

}
