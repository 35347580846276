<template>
  <div class="suppliers">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-9">
            <h1 class="fw-600 fs-24 mb-0">Fornecedores</h1>
          </div>
          <div class="col-md-3 text-right text-end pt-2">
            <router-link class="btn btn-blue" to="/suppliers/create">
              <i class="fas fa-plus mr-2"></i>
              Novo Fornecedor
              </router-link>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="supplier in suppliers.data" :key="supplier.id" class="c-black">
                <td class="align-middle">
                  <router-link :to="'./suppliers/' + supplier.id" class="btn btn-link c-black body-table">
                    {{ supplier.id }}
                  </router-link>
                </td>
                <td class="align-middle">
                  <router-link :to="'./suppliers/' + supplier.id" class="btn btn-link c-black body-table">
                    {{ supplier.name }}
                  </router-link>
                </td>
                <td class="align-middle body-table px-0">
                  <button class="btn ml-4" @click="openDeleteModal(supplier.id)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <PaginateComponent :source="pagination" @navigate="navigate"/>
      </div>
    </div>
    <LoadingComponent :loading="loading || !suppliers.data" />

    <!-- Modal excluir produto -->
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row text-white">
              <div class="col-12 d-flex justify-content-end mb-3">
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="col-8 pb-2 pt-1 pl-3">
                <label class="form-control-label h4">Deseja excluir o produto?</label>
              </div>
              <div class="col-4 text-right">
                <button type="button" class="btn btn-outline-danger" @click="deleteSupplier(supplier_id)">Excluir</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexSuppliersPage',

  components: {
    LoadingComponent,
    PaginateComponent
  },

  data: function () {
    return {
      supplier_id: null
    }
  },

  computed: mapState({
    suppliers: state => state.suppliers.data,
    pagination: state => state.suppliers.pagination,
    loading: state => state.suppliers.loading
  }),

  methods: {

    ...mapActions('suppliers', [
      'index',
      'destroy'
    ]),

    openDeleteModal: function (id) {
      this.supplier_id = id
      $('#deleteModal').modal('show')
    },

    deleteSupplier: function (id) {
      this.destroy(id)
      $('#deleteModal').modal('hide')
    },

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search, filter: this.filter })
      $('#searchModal').modal('hide')
    },

    navigate (page) {
      this.searchWithPaginate(page)
    }

  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
