<template>
  <div class="blogs">
    <div class="row align-items-center justify-content-between mx-0 mb-3">
      <div class="d-flex align-items-center">
      <h1 class="fw-600 fs-24 mb-0">Matérias</h1>
      <!-- <p class="fw-18 fw-400 c-black-1 mb-0 ml-4"> {{ articles.data.length }} {{ articles.data.length >= 2 ? 'publicações' : 'publicação' }}</p> -->
      </div>
      <router-link class="btn btn-blue my-3 my-sm-0" to="/articles/create">
        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path d="M6.99999 12.8333V1.16666M12.8333 6.99999H1.16666" stroke="white" stroke-width="2" stroke-linecap="round"/>
        </svg>
        Nova Publicação
      </router-link>
    </div>

    <!-- Component de publicação -->

    <div class="publications">
      <!-- <PublicationComponent v-for="publication in blogs" :key="publication.id" :publication="publication"/> -->
      <PublicationComponent v-for="article in articles.data" :key="article.id" :article="article"/>
    </div>

    <LoadingComponent :loading="loading" />
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir Blog</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      Deseja excluir o blog?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deleteCategory()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PublicationComponent from './components/PublicationComponent.vue'

export default {

  name: 'IndexBlogPage',

  components: {
    LoadingComponent,
    PublicationComponent
  },

  // computed: mapState({
  //   blogs: state => state.blogs.data,
  //   loading: state => state.blogs.loading
  // }),
  computed: mapState({
    articles: state => state.articles.data,
    loading: state => state.articles.loading,
    pagination: state => state.articles.pagination
  }),

  data: function () {
    return {
      // blog: {},
      // articles: {
      //   data: []
      // },
      article: {
        title: null
      },
      destroy_blog: null,
      publications: [
        { id: 1, title: 'A Importância Vital da Desparasitação em Crianças', status: 'Publicada', date: '01/08/2023', category: 'Categoria', acessos: '1000', description: 'A desparasitação em crianças é uma prática fundamental para garantir o crescimento saudável e o bem-estar de nossos pequenos. Embora possa ser um assunto desco...' },
        { id: 2, title: 'Lua Nova e a desparasitação', status: 'Publicada', date: '31/07/2023', category: 'Categoria', acessos: '1000', description: 'Você sabia que a Lua Nova é uma fase lunar estrategicamente importante para a desparasitação do nosso organismo? Separamos alguns motivos pelos quais é altament...' }
      ]
    }
  },

  methods: {

    ...mapActions('articles', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    deleteBlog: function () {
      this.destroy(this.destroy_blog.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
    // console.log(this.articles.data[0])
  }

}

</script>
<style scoped>
.publications .publication-box {
  /* border-radius: 20px; */
  border: 1px solid #E2E2EA;
  background: #FFF;
  padding: 32px;
}
.publications .publication-box:first-child {
  /* border-radius: 20px; */
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.publications .publication-box:last-child {
  /* border-radius: 20px; */
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
</style>
