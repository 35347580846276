import Vue from 'vue'
import { index, store, show, update, destroy } from '../../services/bank-accounts/bank-accounts'

export const bankAccounts = {

  namespaced: 'bankAccounts',

  state: {
    data: {},
    pagination: {},
    details: {},
    loading: false,
    error: null
  },

  actions: {

    index ({ state, commit }, { page, name }) {
      commit('setLoading', true)
      index(page, name)
        .then(response => {
          commit('setData', response.data.bankAccounts)
          commit('setPagination', response.data.bankAccounts)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    store ({ state, commit }, data) {
      commit('setLoading', true)
      store(data)
        .then(response => {
          commit('setDetails', response.data.bankAccount)
          commit('setLoading', false)
          Vue.toasted.success('Conta bancária adicionado com sucesso!')
          setTimeout(() => {
            window.location.href = '/bank-accounts'
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar conta bancária!')
        })
    },

    show ({ state, commit }, id) {
      commit('setLoading', true)
      show(id)
        .then(response => {
          commit('setDetails', response.data.bankAccount)
          commit('setLoading', false)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
        })
    },

    update ({ state, commit, dispatch }, { id, data }) {
      commit('setLoading', true)
      update(id, data)
        .then(response => {
          commit('setDetails', response.data.bankAccount)
          commit('setLoading', false)
          Vue.toasted.success('Conta bancária atualizado com sucesso!')
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao atualizar conta bancária!')
        })
    },

    destroy ({ state, commit }, id) {
      commit('setLoading', true)
      destroy(id)
        .then(response => {
          commit('setDetails', response.data)
          commit('setLoading', false)
          Vue.toasted.success('Conta bancária deletado com sucesso!')
          setTimeout(() => {
            window.location.href = '/bank-accounts'
          }, 1800)
        })
        .catch(error => {
          commit('setError', error.response)
          commit('setLoading', false)
          Vue.toasted.error('Erro ao deletar conta bancária!')
        })
    }

  },

  mutations: {

    setData (state, bankAccounts) {
      state.data = bankAccounts
    },

    setPagination (state, paginate) {
      state.pagination = paginate
    },

    setDetails (state, bankAccount) {
      state.details = bankAccount
    },

    setLoading (state, status) {
      state.loading = status
    },

    setError (state, error) {
      if (error.status === 401) {
        localStorage.clear()
        window.location.href = './login'
      }
    }

  },

  getters: {}

}
