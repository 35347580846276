<template>
  <div class="block-hover d-block w-100 h-100">
    <div class="d-flex">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M5.99316 7.99675H5.99983V8.00341H5.99316V7.99675ZM5.99316 3.33008H5.99983V3.33674H5.99316V3.33008ZM5.99316 12.6634H5.99983V12.6701H5.99316V12.6634Z" stroke="#7C848F" stroke-width="2" stroke-linejoin="round"/>
      <path d="M10 7.99675H10.0067V8.00341H10V7.99675ZM10 3.33008H10.0067V3.33674H10V3.33008ZM10 12.6634H10.0067V12.6701H10V12.6634Z" stroke="#7C848F" stroke-width="2" stroke-linejoin="round"/>
    </svg>
    <vue-editor v-model='text_block.content' :editor-toolbar='customToolbar' />
    </div>
    <div class="position-icon pointer">
      <svg @click="$emit('openDelete')" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3 6H5H21" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 11V17" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14 11V17" stroke="#FC5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>

import { VueEditor } from 'vue2-editor'

export default {

  components: { VueEditor },

  data: () => ({
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: 'center' }, { align: '' }, { align: 'right' }, { align: 'justify' }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ['link']
    ],
    text_block: {
      content: ''
    },
    text: null
  }),

  props: {
    _text_block: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  mounted () {
    this.text_block = this._text_block
  }
}
</script>
<style scoped scss>
.block-hover {
  position: relative;
}
.position-icon {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 8px;
  background: rgba(250, 250, 251, 0.75);
  padding: 8px;
  opacity: 0;
  transition: .3s;
}
.block-hover:hover .position-icon {
  opacity: 1;
  transition: .3s;
}
.quillWrapper {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
