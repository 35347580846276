<template>
  <div class="login">
    <div class="row justify-content-center mt-5 pt-5">
      <div class="col-md-12 mb-5 text-center">
        <img :src="preferences.data.avatar" height="80" alt="">
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 mx-auto">
        <div class="card p-5">
          <h1 class="">{{ step == 'login' ? 'Login' : 'Esqueci minha senha' }}</h1>
          <hr>
          <form @submit.prevent="step == 'login' ? login(user) : forgot(user)">
            <div class="form-group">
              <label for="email" class="control-label">E-mail:</label>
              <input v-model="user.email" type="email" class="form-control" placeholder="Digite seu email" required>
            </div>
            <div v-if="step == 'login'" class="form-group">
              <label for="password" class="control-label">Senha:</label>
              <input v-model="user.password" type="password" class="form-control" placeholder="Digite sua senha" required>
            </div>
            <button class="btn btn-success w-100 py-3">
              {{ step == 'login' ? 'Entrar' : 'Enviar e-mail' }}
            </button>
            <button type="button" @click="step == 'login' ? step = 'reset' : step = 'login'" class="btn btn-light border mt-3 btn-sm w-100 py-2">
              {{ step == 'login' ? 'Esqueci minha senha' : 'Voltar' }}
            </button>
          </form>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="auth.loading" />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'LoginPage',

  components: {
    LoadingComponent
  },

  data () {
    return {
      step: 'login',
      user: {
        email: null,
        password: null
      }
    }
  },

  computed: mapState({
    preferences: state => state.preferences,
    auth: state => state.auth
  }),

  methods: {

    ...mapActions('auth', [
      'login',
      'forgot'
    ])

  }

}

</script>
