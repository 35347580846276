<template>
  <div class="sizes">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Tamanhos dos Produtos</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Novo Tamanho
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="size in sizes.data" :key="size.id">
                <td class="align-middle body-table">
                    {{ size.id }}
                </td>
                <th class="align-middle body-table">
                    {{ size.description }}
                </th>
                <td class="align-middle body-table">
                  <button v-if="size.company_id" class="btn mr-2" data-toggle="modal" @click="openUpdateModal(size)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button v-if="size.company_id" class="btn" data-toggle="modal" @click="openDeleteModal(size)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <PaginateComponent :source="pagination" @navigate="navigate"/>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !sizes.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (size.id ? 'Editar Tamanho' : 'Cadastrar Tamanho')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="size.description" required>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updatesize()" v-if="size.id">Editar Tamanho</button>
            <button type="button" class="btn btn-success" @click="addsize()" v-else>Cadastrar Tamanho</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir Tamanho</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      Deseja excluir o Tamanho?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deletesize()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import LoadingComponent from '../../components/layout/LoadingComponent'
import PaginateComponent from '../../components/PaginateComponent'

export default {

  name: 'IndexsizesPage',

  components: {
    PaginateComponent,
    LoadingComponent
  },

  computed: mapState({
    sizes: state => state.sizes.data,
    pagination: state => state.sizes.pagination,
    loading: state => state.sizes.loading
  }),

  data: function () {
    return {
      size: {
        description: null
      },
      destroy_size: null
    }
  },

  methods: {

    ...mapActions('sizes', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    searchWithPaginate (page) {
      this.index({ page: page, search: this.search })
    },

    navigate (page) {
      this.searchWithPaginate(page)
    },

    addsize: function () {
      this.store(this.size)
      $('#CreateModal').modal('hide')
    },

    openCreateModal: function () {
      this.size = {
        description: null
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (size) {
      this.size = size
      $('#CreateModal').modal()
    },

    openDeleteModal: function (size) {
      this.destroy_size = size
      $('#openDeleteModal').modal()
    },

    updatesize: function () {
      this.update({ id: this.size.id, data: this.size })
      $('#CreateModal').modal('hide')
    },

    deletesize: function () {
      this.destroy(this.destroy_size.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
  }

}

</script>
